<template>
  <div class="px-0">
    <div class="row">
      <div class="col">
        <dimmer :active="submitting">
          <div class="card px-0 border-0">
            <div class="card-header px-0 pb-2 pt-0">
              <form
                v-disable-all="!can(uiPermissions.MENU_SCHEDULE_UPDATE)"
                class="row w-100 justify-content-end align-items-center"
                @submit.prevent="search">
                <div
                  v-if="themeAdded"
                  v-b-tooltip="'Only display themed recipes'"
                  class="mr-4">
                  <label class="form-label">Themed recipes</label>
                  <h-switch :value="isThemed" @input="(newValue) => isThemed = newValue"/>
                </div>
                <div v-b-tooltip="'Only display customizable recipes'" class="mr-4">
                  <label class="form-label">Customizable recipes</label>
                  <h-switch :value="customizable" @input="(newValue) => customizable = newValue"/>
                </div>
                <table-filters
                  :value="selectedFilters"
                  :options="filterOptions"
                  class="justify-content-end mr-2 d-flex"
                  @input="setSelectedTags"
                  @remove="removeTag"
                  @reset="resetTags"
                  @search="search"/>
                <div class="row justify-content-end">
                  <div class="col form-group mb-0">
                    <input
                      v-model="query"
                      type="search"
                      class="form-control mr-2"
                      placeholder="Search"
                      tabindex="1">
                  </div>
                  <div class="col-auto p-0">
                    <button class="btn btn-primary">
                      <i class="fe fe-search"></i>
                    </button>
                  </div>

                </div>
              </form>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-2">
              <sort-dropdown
                :value="selectedSort"
                :options="sortOptions"
                class="d-flex"
                @input="setSort"
                @reset="resetSort"
                @sort-direction="setDirection"/>
            </div>
            <div class="card-body p-0">
              <div v-if="item.recipes.length" class="table-responsive">
                <table class="table table-hover table-outline table-vcenter card-table">
                  <thead>
                    <tr>
                      <th class="w-1"></th>
                      <th>Recipe Name</th>
                      <th class="text-nowrap">Historical data</th>
                      <th class="text-center">Status</th>
                      <th class="w-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="recipe in item.recipes"
                      :key="recipe.id"
                      class="cursor-pointer">
                      <td class="text-center" @click="$emit('select', recipe)">
                        <div class="item-action">
                          <i class="text-success fe fe-plus" style="font-size: 1.5rem"></i>
                        </div>
                      </td>
                      <td @click="$emit('select', recipe)">
                        <div class="d-flex align-items-center">
                          <a
                            v-if="recipe.photo"
                            :href="recipe.photo.url"
                            target="_blank"
                            rel="noreferrer noopener"
                            @click.stop>
                            <img :src="getTransformationUrl(recipe.photo, {height: 50, width: 50, crop: 'fill'})" class="avatar avatar-md"/>
                          </a>
                          <div class="ml-2">
                            <a
                              :href="`/recipes/${recipe.id}`"
                              target="_blank"
                              rel="noreferrer noopener"
                              @click.stop>{{recipe.name}}</a>
                            <div class="text-muted d-flex flex-wrap custom-gap my-1">
                              <small><strong>Calories:</strong> {{getCalories(recipe)}}</small>
                              <small><strong>Cooking time:</strong> {{recipe.cooking_time}} mins.</small>
                              <small><strong>Cost/serving:</strong> AED {{numeral(recipe.cost).format('0,0.00')}}</small>
                              <small><strong>Frequency:</strong> {{recipe.frequency}}</small>
                              <small><strong>Last published:</strong> {{recipe.lastPublished ? moment(recipe.lastPublished).format('DD-MM-YYYY') : 'N/A'}}</small>
                            </div>
                            <div class="list-tags d-flex flex-wrap custom-gap">
                              <template v-if="recipe.tags.length">
                                <small
                                  v-for="tag in uniqueBy(recipe.tags, 'id')"
                                  :key="tag.id"
                                  class="tag tag-primary">{{tag.name.toLowerCase()}}</small>
                              </template>
                              <small v-if="recipe.weeklyClassic" class="tag tag-warning">weekly-classic</small>
                              <small
                                v-for="feature in recipe.features"
                                :key="feature.id"
                                class="tag tag-warning">{{feature.name}}</small>
                              <small
                                v-for="feature in recipe.dynamic_features"
                                :key="feature"
                                class="tag tag-warning">{{feature}}</small>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-nowrap" @click="$emit('select', recipe)">
                        <p class="mb-0">Uptake: {{recipe.historicalUptakes}}</p>
                        <p class="mb-0">Rating: {{numeral(recipe.avgRating).format('0,0[.]00') || 0}} / 5</p>
                      </td>
                      <td class="text-center" @click="$emit('select', recipe)">
                        <div>{{statusName(recipe.status)}}</div>
                      </td>
                      <td class="text-center">
                        <div class="item-action dropdown">
                          <a
                            tabindex="0"
                            data-toggle="dropdown"
                            class="icon"><i class="fe fe-more-vertical"></i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <archive-recipe :recipe="recipe" @archived="fetch()"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else-if="!submitting" class="text-center">
                <h3>No recipes found</h3>
              </div>
            </div>
          </div>
        </dimmer>
      </div>

    </div>
  </div>

</template>

<script>

import submitting from '@hellochef/shared-js/mixins/submitting';
import {tags, weeklyMenus} from '@/services';
import {uniqBy, startCase} from 'lodash';
import TableFilters from '@/components/filters/TableFilters';
import SortDropdown from '@/components/filters/SortDropdown';
import HSwitch from '@/components/ui/HSwitch';
import ArchiveRecipe from '@/views/recipes/components/ArchiveRecipe';

const NUTRITION_CALORIES = 'Calories';
const CUSTOM_FILTERS = ['features'];
const GOURMET_ID = 12;

export default {
  components: {
    ArchiveRecipe,
    HSwitch,
    TableFilters,
    SortDropdown,
  },
  mixins: [
    submitting,
  ],
  props: [
    'value',
    'startDate',
    'themeAdded',
  ],
  data() {
    return {
      item: {
        recipes: [],
        tags: [],
      },
      original: {
        recipes: [],
      },
      query: null,
      selectedFilters: [],
      selectedSort: null,
      customizable: false,
      isThemed: false,
    };
  },
  computed: {
    uniqueBy() { return uniqBy; },
    sortOptions() {
      return [
        {
          model: 'cost',
          label: 'Cost',
          direction: 'asc',
        },
        {
          model: 'frequency',
          label: 'Frequency',
          direction: 'asc',
        },
        {
          model: 'calories',
          label: 'Calories',
          direction: 'asc',
          customSort: () => this.sortByCalories(),
        },
        {
          model: 'cooking_time',
          label: 'Cooking time',
          direction: 'asc',
        },
        {
          model: 'historicalUptakes',
          label: 'Historical uptakes',
          direction: 'asc',
          customSort: () => this.sortByHistoricalUptake(),
        },
        {
          model: 'avgRating',
          label: 'Average ratings',
          direction: 'asc',
        },
        {
          model: 'nps',
          label: 'NPS',
          direction: 'asc',
        },
      ];
    },
    allFeatures() {
      const features = this.item.recipes.flatMap(recipe => recipe.features.map(feature => feature.name));
      const dynamicFeatures = this.item.recipes.flatMap(recipe => recipe.dynamicFeatures);
      const combined = Array.from(new Set([...features, ...dynamicFeatures])).filter(item => item);
      return combined.filter(item => item);
    },
    filterOptions() {
      const tagFilters = this.item.tags.map(tagGroup => ({
        label: tagGroup.name,
        model: tagGroup.id,
        options: tagGroup.tags.map(tag => Object.assign(tag, {group: tagGroup.name, groupId: tagGroup.id})),
        multiple: true,
      }));

      const otherFilters = {
        label: 'Features',
        model: 'features',
        options: this.allFeatures.map(item => ({
          id: item,
          name: item,
          group: 'features',
          groupId: 'features',
        })),
        multiple: true,
      };

      return [...tagFilters, otherFilters];
    },
  },
  watch: {
    customizable() {
      this.fetch();
    },
    isThemed() {
      this.fetch();
    },
  },
  async created() {
    const result = await tags.getGroupedTags();
    this.item.tags = result.filter(item => item.tags.length);
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getCalories(recipe) {
      return recipe.nutritions?.find(nutrition => nutrition.name === NUTRITION_CALORIES)?.value || 'N/A';
    },
    statusName(name) {
      return startCase(name);
    },
    sortByHistoricalUptake() {
      return this.item.recipes = [...this.original.recipes].sort((a, b) => {
        const aNum = this.getNumberFromUptakesString(a.historicalUptakes);
        const bNum = this.getNumberFromUptakesString(b.historicalUptakes);
        return this.selectedSort.direction === 'desc' ? bNum - aNum : aNum - bNum;
      });
    },
    sortByCalories() {
      return this.item.recipes = [...this.original.recipes].sort((a, b) => {
        const aNum = a.nutritions?.find(nutrition => nutrition.name === NUTRITION_CALORIES)?.value || 0;
        const bNum = b.nutritions?.find(nutrition => nutrition.name === NUTRITION_CALORIES)?.value || 0;
        return this.selectedSort.direction === 'desc' ? bNum - aNum : aNum - bNum;
      });
    },
    sortByLastPublished() {
      return this.item.recipes = [...this.original.recipes].sort((a, b) => {
        if (!a.lastPublished) return 1;
        if (!b.lastPublished) return -1;
        return new Date(b.lastPublished) - new Date(a.lastPublished);
      });
    },
    getNumberFromUptakesString(str) {
      return Number(str.substr(0, str.length - 1));
    },
    sorting(isAsc, arr, propName) {
      return arr.sort((a, b) =>
        isAsc
          ? Number(a[propName]) - Number(b[propName])
          : Number(b[propName]) - Number(a[propName]),
      );
    },
    setSort(newValue) {
      this.selectedSort = newValue;

      if (this.selectedSort?.customSort) {
        return this.selectedSort.customSort();
      }

      this.item.recipes = this.sorting(this.selectedSort.direction === 'asc', [...this.original.recipes], this.selectedSort.model);
    },
    setDirection({direction, selectedValue}) {
      selectedValue.direction = direction;

      this.setSort(selectedValue);
    },
    resetSort() {
      this.selectedSort = null;
      this.sortByLastPublished();
    },
    fetch() {
      this.submitIt(async () => {
        const tags = this.selectedFilters.filter(tag => !CUSTOM_FILTERS.includes(tag.groupId)).map(tag => tag.id);

        const payload = {
          startDate: this.startDate,
          mealPlan: this.value.mealPlan,
          protein: this.value.protein,
          customizable: +this.customizable,
          isThemed: this.isThemed,
        };

        if (this.value.feature) { payload.feature = this.value.feature; }

        if (tags.length) { payload.tags = tags; }

        if (this.query) { payload.name = this.query; }

        if (this.value.weeklyClassic) { payload.weeklyClassic = this.value.weeklyClassic; }

        /**
         * we are sending only gourmet feature to the server, rest of them is filtered in the client
         * so, we just override the feature to be the gourmet id and also pass the is_gourmet_slot flag to indicate a gourmet recipe
         */
        if (payload.feature?.toLowerCase() === 'gourmet') {
          // 1 indicates true
          payload.is_gourmet_slot = 1;
          payload.feature = GOURMET_ID;
        }

        const {suggestions} = await weeklyMenus.suggest(payload);

        this.item.recipes = this.original.recipes = suggestions.map(recipe => Object.assign(recipe, {costPerServing: recipe.cost}));

        // default sort by lastPublished
        this.sortByLastPublished();

        if (this.selectedSort) {
          this.setSort(this.selectedSort);
        }

        // filter out recipe for selected features
        if (this.selectedFilters && this.selectedFilters.some(filter => filter.group === 'features')) {
          const featureFilters = this.selectedFilters.filter(filter => filter.group === 'features').map(item => item.name);
          this.item.recipes = this.item.recipes.filter(recipe => recipe.features.some(feature => featureFilters.includes(feature.name)));
        }
      });
    },
    search() {
      this.fetch();
    },
    removeTag(index) {
      this.selectedFilters.splice(index, 1);
    },
    resetTags() {
      this.selectedFilters = [];
    },
    setSelectedTags(newValue) {
      this.selectedFilters = Array.from(new Set(this.selectedFilters.concat(newValue)));
    },
  },
};

</script>

<style scoped>
  .custom-gap {
    gap: 5px;
  }
  .avatar-md {
    min-width: 2.5em;
  }
</style>
